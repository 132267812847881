import { createTantoSvagoSlice, TantoSvagoState } from '@/store/createTantoSvagoSlice';
import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';
import { createBookingInformationsSlice, BookingInformationsState } from './createBookingInformationsSlice';
import { createCheckoutSlice, CheckoutState } from './createCheckoutSlice';
import { createCommonSlice, CommonState } from '@davincihealthcare/booking-store';

export const useInclinicStore = create<BookingInformationsState & CheckoutState & CommonState>()(
  devtools(
    persist(
      (...args) => ({
        ...createBookingInformationsSlice(...args),
        ...createCheckoutSlice(...args),
        ...createCommonSlice(...args),
      }),
      {
        name: 'inclinic-store',
        partialize: state => Object.fromEntries(Object.entries(state).filter(([key]) => !['isBookingInformationsLoading'].includes(key))),
      },
    ),
  ),
);

export const useTantoSvagoStore = create<TantoSvagoState>()(
  devtools(
    persist(
      (...args) => ({
        ...createTantoSvagoSlice(...args),
      }),
      {
        name: 'tantosvago-store',
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
  ),
);
