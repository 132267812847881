import { DetailedSlot } from '@/types-aggregatore';
import { ReservationOrigin } from '@/types-core';
import { StateCreator } from 'zustand';

interface BookingInformationsmHandlers {
  setBookingInformation: (bookingInformation: BookingInformationFields) => void;
  setReservationOrigin: (reservationOrigin: ReservationOrigin | undefined) => void;
  setAllowedPayments: (allowedPayments: ClinicAllowedPayments) => void;
  resetBookingInformation: () => void;
  setBookingInformationLoading: (isLoading: boolean) => void;
}

export interface ClinicAllowedPayments {
  online: boolean;
  onsite: boolean;
  deferred: boolean;
}

export type BookingInformationFields = {
  slot: DetailedSlot | undefined;
  // startTime: number;
  clinicAllowedPayments: ClinicAllowedPayments;
  reservationOrigin?: ReservationOrigin | undefined;
};

interface BookingInformationsContent {
  bookingInformations: BookingInformationFields;
}

interface BookingInformationsOthers {
  isBookingInformationsLoading: boolean;
}

export interface BookingInformationsState extends BookingInformationsmHandlers, BookingInformationsContent, BookingInformationsOthers {}

const initialBookingInformation: BookingInformationFields = {
  slot: undefined,
  clinicAllowedPayments: {
    online: false,
    onsite: false,
    deferred: false,
  },
  reservationOrigin: undefined,
};

export const createBookingInformationsSlice: StateCreator<BookingInformationsState, [], [], BookingInformationsState> = set => ({
  bookingInformations: initialBookingInformation,
  isBookingInformationsLoading: true,

  setBookingInformation: booking => {
    set({ bookingInformations: { ...booking } });
  },

  setAllowedPayments: allowedPayments => {
    set(state => ({ bookingInformations: { ...state.bookingInformations, clinicAllowedPayments: allowedPayments } }));
  },

  setReservationOrigin: reservationOrigin => {
    set(state => ({ bookingInformations: { ...state.bookingInformations, reservationOrigin } }));
  },

  resetBookingInformation: () => {
    set({
      bookingInformations: { ...initialBookingInformation },
      isBookingInformationsLoading: true,
    });
  },
  setBookingInformationLoading: isBookingInformationsLoading =>
    set({
      isBookingInformationsLoading,
    }),
});
