/* eslint-disable no-process-env */
import { z } from 'zod';
import { isTrue } from '@/utils/string';

const publicSchema = z.object({
  NEXT_PUBLIC_INCLINIC_GATEWAY_URL: z.string(),
  NEXT_PUBLIC_ENV: z.enum(['development', 'staging', 'qa', 'production']),
  NEXT_PUBLIC_BACKEND_URL: z.string(),
  NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY: z.string(),
  NEXT_PUBLIC_ALGOLIA_SEARCH_KEY: z.string(),
  NEXT_PUBLIC_ALGOLIA_APP_ID: z.string(),
  NEXT_PUBLIC_DAVINCI_ASSETS_CDN: z.string().default('https://cdn.davinci.care'),
  NEXT_PUBLIC_ALGOLIA_INDEX: z.string().default('clinic'),
  NEXT_PUBLIC_STORYBLOK_VERSION: z.enum(['draft', 'published']),
  NODE_ENV: z.string(),
  NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN: z.string(),
  NEXT_PUBLIC_MAP_URL: z.string(),
  NEXT_PUBLIC_FLOW_ID_SURVEY_SISALUTE: z.string(),
  NEXT_PUBLIC_FLOW_ID_SURVEY_ARGENTO: z.string().nullish(),
  NEXT_GOOGLE_CONVERSION_ENDPOINT: z.string(),
  NEXT_GOOGLE_PARTNER_ID: z.string(),
  NEXT_GOOGLE_BUSINESS_TOKEN_TEST: z.string(),
  NEXT_PUBLIC_WEBAPP_URL: z.string(),
  NEXT_PUBLIC_SITE_URL: z.string(),
  NEXT_PUBLIC_BACKEND_URL_FOR_STATIC_BUILD: z.string(),
  NEXT_PUBLIC_SKIP_BUILD_BOOKING_STATIC_GENERATION: z.boolean().default(false),
  NEXT_PUBLIC_FLOW_ID_SURVEY_ACUORE: z.string(),
  NEXT_PUBLIC_FLOW_ID_SURVEY_CASSALOMBARDA: z.string(),
});

const serverOnlySchema = publicSchema.extend({
  BACKEND_URL_FOR_STATIC_BUILD: z.string(),
  SKIP_BUILD_STATIC_GENERATION: z.boolean().default(false),
  STORYBLOK_TOKEN: z.string(),
  STORYBLOK_TOKEN_DRAFT: z.string(),
  SECRET_NEXTJS_REVALIDATION_TOKEN: z.string(),
  SECRET_NEXTJS_DRAFT_MODE_TOKEN: z.string(),
  NEXT_PHASE: z.string().nullish(),
  SLACK_BUILD_MESSAGES_TOKEN: z.string(),
  SLACK_BUILD_MESSAGES_CHANNEL: z.string(),
  DISABLE_REDIS_CACHE: z.boolean().default(true),
  REDIS_HOST: z.string(),
  GITHUB_API_TOKEN: z.string().default(''),
  DATE_SERVER_START: z.string().optional().default(''),
  LAST_COMMIT_ID: z.string().optional().default('NOT_AVAILABLE'),
});

export type ClientConfig = z.infer<typeof publicSchema>;
export const CLIENT_ENV = () => {
  const envs = publicSchema.parse({
    NEXT_PUBLIC_INCLINIC_GATEWAY_URL: process.env.NEXT_PUBLIC_INCLINIC_GATEWAY_URL,
    NEXT_PUBLIC_ENV: process.env.NEXT_PUBLIC_ENV,
    NEXT_PUBLIC_BACKEND_URL: process.env.NEXT_PUBLIC_BACKEND_URL,
    NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,
    NEXT_PUBLIC_ALGOLIA_SEARCH_KEY: process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY,
    NEXT_PUBLIC_ALGOLIA_APP_ID: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
    NEXT_PUBLIC_DAVINCI_ASSETS_CDN: process.env.NEXT_PUBLIC_DAVINCI_ASSETS_CDN,
    NEXT_PUBLIC_ALGOLIA_INDEX: process.env.NEXT_PUBLIC_ALGOLIA_INDEX,
    NEXT_PUBLIC_STORYBLOK_VERSION: process.env.NEXT_PUBLIC_STORYBLOK_VERSION,
    NODE_ENV: process.env.NODE_ENV,
    NEXT_GOOGLE_CONVERSION_ENDPOINT: process.env.NEXT_GOOGLE_CONVERSION_ENDPOINT,
    NEXT_GOOGLE_PARTNER_ID: process.env.NEXT_GOOGLE_PARTNER_ID,
    NEXT_GOOGLE_BUSINESS_TOKEN_TEST: process.env.NEXT_GOOGLE_BUSINESS_TOKEN_TEST,
    NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN: process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN,
    NEXT_PUBLIC_MAP_URL: process.env.NEXT_PUBLIC_MAP_URL,
    NEXT_PUBLIC_FLOW_ID_SURVEY_SISALUTE: process.env.NEXT_PUBLIC_FLOW_ID_SURVEY_SISALUTE,
    NEXT_PUBLIC_FLOW_ID_SURVEY_ARGENTO: process.env.NEXT_PUBLIC_FLOW_ID_SURVEY_ARGENTO,
    NEXT_PUBLIC_WEBAPP_URL: process.env.NEXT_PUBLIC_WEBAPP_URL,
    NEXT_PUBLIC_SITE_URL: process.env.NEXT_PUBLIC_SITE_URL,
    NEXT_PUBLIC_BACKEND_URL_FOR_STATIC_BUILD: process.env.BACKEND_URL_FOR_STATIC_BUILD,
    NEXT_PUBLIC_FLOW_ID_SURVEY_ACUORE: process.env.NEXT_PUBLIC_FLOW_ID_SURVEY_ACUORE,
    NEXT_PUBLIC_FLOW_ID_SURVEY_CASSALOMBARDA: process.env.NEXT_PUBLIC_FLOW_ID_SURVEY_CASSALOMBARDA,
  });

  return envs;
};

export type ServerConfig = z.infer<typeof serverOnlySchema>;
export const SERVER_ENV = (): ServerConfig => {
  const envs = serverOnlySchema.parse({
    NEXT_PUBLIC_INCLINIC_GATEWAY_URL: process.env.NEXT_PUBLIC_INCLINIC_GATEWAY_URL,
    NEXT_PUBLIC_SITE_URL: process.env.NEXT_PUBLIC_SITE_URL,
    BACKEND_URL_FOR_STATIC_BUILD: process.env.BACKEND_URL_FOR_STATIC_BUILD,
    SKIP_BUILD_STATIC_GENERATION: isTrue(process.env.SKIP_BUILD_STATIC_GENERATION),
    NODE_ENV: process.env.NODE_ENV,
    NEXT_PUBLIC_ENV: process.env.NEXT_PUBLIC_ENV,
    NEXT_PUBLIC_BACKEND_URL: process.env.NEXT_PUBLIC_BACKEND_URL,
    NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,
    NEXT_PUBLIC_ALGOLIA_SEARCH_KEY: process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY,
    NEXT_PUBLIC_ALGOLIA_APP_ID: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
    NEXT_PUBLIC_DAVINCI_ASSETS_CDN: process.env.NEXT_PUBLIC_DAVINCI_ASSETS_CDN,
    NEXT_PUBLIC_ALGOLIA_INDEX: process.env.NEXT_PUBLIC_ALGOLIA_INDEX,
    NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN: process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN,
    NEXT_PUBLIC_STORYBLOK_VERSION: process.env.NEXT_PUBLIC_STORYBLOK_VERSION,
    NEXT_PUBLIC_FLOW_ID_SURVEY_SISALUTE: process.env.NEXT_PUBLIC_FLOW_ID_SURVEY_SISALUTE,
    STORYBLOK_TOKEN: process.env.STORYBLOK_TOKEN,
    STORYBLOK_TOKEN_DRAFT: process.env.STORYBLOK_TOKEN_DRAFT,
    SECRET_NEXTJS_REVALIDATION_TOKEN: process.env.SECRET_NEXTJS_REVALIDATION_TOKEN,
    NEXT_GOOGLE_CONVERSION_ENDPOINT: process.env.NEXT_GOOGLE_CONVERSION_ENDPOINT,
    NEXT_GOOGLE_PARTNER_ID: process.env.NEXT_GOOGLE_PARTNER_ID,
    NEXT_GOOGLE_BUSINESS_TOKEN_TEST: process.env.NEXT_GOOGLE_BUSINESS_TOKEN_TEST,
    SECRET_NEXTJS_DRAFT_MODE_TOKEN: process.env.SECRET_NEXTJS_DRAFT_MODE_TOKEN,
    NEXT_PHASE: process.env.NEXT_PHASE,
    NEXT_PUBLIC_MAP_URL: process.env.NEXT_PUBLIC_MAP_URL,
    NEXT_PUBLIC_WEBAPP_URL: process.env.NEXT_PUBLIC_WEBAPP_URL,
    NEXT_PUBLIC_BACKEND_URL_FOR_STATIC_BUILD: process.env.BACKEND_URL_FOR_STATIC_BUILD,
    NEXT_PUBLIC_SKIP_BUILD_BOOKING_STATIC_GENERATION: isTrue(process.env.NEXT_PUBLIC_SKIP_BUILD_BOOKING_STATIC_GENERATION),
    NEXT_PUBLIC_FLOW_ID_SURVEY_ACUORE: process.env.NEXT_PUBLIC_FLOW_ID_SURVEY_ACUORE,
    NEXT_PUBLIC_FLOW_ID_SURVEY_CASSALOMBARDA: process.env.NEXT_PUBLIC_FLOW_ID_SURVEY_CASSALOMBARDA,
    SLACK_BUILD_MESSAGES_TOKEN: process.env.SLACK_BUILD_MESSAGES_TOKEN,
    SLACK_BUILD_MESSAGES_CHANNEL: process.env.SLACK_BUILD_MESSAGES_CHANNEL,

    DISABLE_REDIS_CACHE: isTrue(process.env.DISABLE_REDIS_CACHE),
    DATE_SERVER_START: process.env.DATE_SERVER_START,
    REDIS_HOST: process.env.REDIS_HOST,
    GITHUB_API_TOKEN: process.env.GITHUB_API_TOKEN,
    LAST_COMMIT_ID: process.env.LAST_COMMIT_ID,
  });

  return envs;
};
