const onBehalfOfOptions = [
  { id: 'ME', label: 'Me stesso/a' },
  { id: 'SPOUSE', label: 'Coniuge' },
  { id: 'SON', label: 'Figlio/a' },
  { id: 'PARENT', label: 'Genitore' },
  { id: 'SON_IN_LAW', label: 'Genero/Nuora' },
  { id: 'FATHER_IN_LAW', label: 'Suocero/a' },
  { id: 'BROTHER', label: 'Fratello/Sorella' },
  { id: 'OTHER', label: 'Altro' },
];

export const onBehalfOfDefaultValue = (): string => {
  return onBehalfOfOptions?.find(item => item.id === 'ME')?.label ?? 'Me stesso/a';
};

export const onBehalfOfStringOptions = (params: { usingWelfareProvider?: boolean }) => {
  if (params.usingWelfareProvider) {
    return onBehalfOfOptions.filter(o => o.id !== 'OTHER').map(i => ({ value: i.label }));
  }

  return onBehalfOfOptions.map(i => ({ value: i.label }));
};
