export enum AUTOCOMPLETE {
  AMBULATORY = 'AMBULATORY',
  SPECIALIZATION = 'SPECIALIZATION',
  SPECIALITY = 'SPECIALITY',
  LOCATION = 'LOCATION',
  SPECIALITIES = 'SPECIALITIES',
  DISTRICTS_CITIES = 'DISTRICTS_CITIES',
}

export enum MATCH_LEVEL {
  FULL = 'full',
  PARTIAL = 'partial',
  NONE = 'none',
}

export enum LINK_TYPE {
  HREF = 'href',
  LINK = 'link',
  JSX = 'jsx',
}

export enum DeliveryModeTypes {
  Domiciliary = 'Domiciliary',
  Onsite = 'Onsite',
  Online = 'Online',
}

export enum CheckoutFlowType {
  RESERVATION = 'RESERVATION',
  SUBSCRIPTION = 'SUBSCRIPTION',
  RESERVATION_DEFERRED_PAYMENT = 'RESERVATION_DEFERRED_PAYMENT',
}
export enum PaymentMethod {
  STRIPE = 'stripe',
  TANTOSVAGO = 'tantosvago',
  WELBEE = 'welbee',
  INPLACE = 'inplace',
  STRIPE_WITH_TRIAL_DAYS = 'stripe_with_trial_days',
}
export enum CheckoutStatusType {
  COMPLETED = 'completed',
  NOT_COMPLETED = 'not_completed',
}

export enum TaxCodeCalculatorStep {
  CALCULATE = 'calculate',
  RECAP = 'recap',
  MANUAL_INSERT = 'manual_insert',
}
