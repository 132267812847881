import { StateCreator } from 'zustand';

interface TantoSvagoHandlers {
  updateSSO: (value: TantoSvagoState) => void;
  resetSSO: () => void;
}

export interface TantoSvagoFields {
  sso: string;
  endpointSyncOrderUrl: string;
  endpointAsyncOrderUrl: string;
  endpointAsyncConfirmOrderUrl: string;
  endpointDiscountCodeValidationUrl: string;
  endpointAsyncOrderWithPaymentGatewayUrl: string;
  apiHeaders: {
    'Content-Type': string;
    Authorization: string;
    proj: string;
    sessionId: string;
  };
  logoImageUrl: string;
  contactText: string;
  primaryColor: string;
  secondaryColor: string;
  projectCode: string;
  tag: string;
  paymentTypes: 'welfare';
  callbackUrl: string;
  userInfo: {
    firstName: string;
    lastName: string;
    email: string;
    userId: string;
    ssn: string;
    phone: string;
  };
}

export interface TantoSvagoState extends TantoSvagoHandlers, TantoSvagoFields {}

export const defaultTantoSvagoState: TantoSvagoFields = {
  sso: '',
  endpointSyncOrderUrl: '',
  endpointAsyncOrderUrl: '',
  endpointAsyncConfirmOrderUrl: '',
  endpointDiscountCodeValidationUrl: '',
  endpointAsyncOrderWithPaymentGatewayUrl: '',
  apiHeaders: {
    'Content-Type': '',
    Authorization: '',
    proj: '',
    sessionId: '',
  },
  logoImageUrl: '',
  contactText: '',
  primaryColor: '',
  secondaryColor: '',
  projectCode: '',
  tag: '',
  paymentTypes: 'welfare',
  callbackUrl: '',
  userInfo: {
    firstName: '',
    lastName: '',
    email: '',
    userId: '',
    ssn: '',
    phone: '',
  },
};

export const createTantoSvagoSlice: StateCreator<TantoSvagoState, [], [], TantoSvagoState> = set => ({
  ...defaultTantoSvagoState,
  updateSSO: (value: TantoSvagoState): void => set({ ...value }),
  resetSSO: (): void => set({ ...defaultTantoSvagoState }),
});
