export const isTrue = (value?: string | boolean) => String(value).toLowerCase() === 'true' || value === true;

/**
 * from "hello" to "Hello"
 */
export const capitalizeFirstCharInString = (str?: null | string) => (str ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() : '');

/**
 * from "hello developer" to "Hello Developer"
 */
export const capitalizeWordsInString = (str?: null | string) =>
  str
    ? str
        .split(' ')
        .map(word => capitalizeFirstCharInString(word))
        .join(' ')
    : '';

export const getStringFromQueryParam = (param: string | string[] = ''): string => {
  return Array.isArray(param) ? param[0] : param;
};

export const getEnumValueFromString = <T extends Record<string, string>>(enumObject: T, stringValue: string): T[keyof T] | undefined => {
  const keys = Object.keys(enumObject) as Array<keyof T>;
  for (const key of keys) {
    if (enumObject[key] === stringValue) {
      return enumObject[key];
    }
  }
  return undefined;
};

export const normalizeString = (initialString: string) => initialString.replaceAll(/\s/g, '').toLowerCase();
